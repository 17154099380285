import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { StateInterface } from "@state/ports/interfaces";

export abstract class StateAbstraction {
  abstract list(query: string): Observable<RespListInterface<StateInterface>>;
  abstract create(state: StateInterface): Observable<RespInterface<StateInterface>>;
  abstract update(state: StateInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<StateInterface>>;
  abstract delete(id: string): Observable<RespInterface<StateInterface>>;
}
